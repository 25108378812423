<template>
  <div>
    <el-form ref="form" :model="form" inline size="small">
      <!-- 平台 -->
      <el-form-item :label="$t('title.platform')">
        <el-select v-model="form.platform" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
          <el-option
            v-for="item in platformList"
            :key="item.id"
            :label="item.platformCode"
            :value="item.platformCode"
          />
        </el-select>
      </el-form-item>
      <!--状态-->
      <el-form-item :label="$t('page.status')">
        <el-select v-model="form.templateStatus" :placeholder="$t('page.selectPlaceholder')" clearable filterable @change="queryClick">
          <el-option :label="$t('page.enabled')" value="0" />
          <el-option :label="$t('page.disable')" value="1" />
        </el-select>
      </el-form-item>
      <!--分类-->
      <el-form-item :label="$t('title.classify')">
        <el-select v-model="form.typeName" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.typeName"
            :value="item.typeName"
          />
        </el-select>
      </el-form-item>
      <!--模板类型-->
      <el-form-item :label="$t('page.templateType')">
        <el-select v-model="form.templateType" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
          <el-option
            v-for="item in templateTypeList"
            :key="item.value"
            :label="item.label"
            :value="+item.value"
          />
        </el-select>
      </el-form-item>
      <!--标题-->
      <el-form-item v-if="showRow" :label="$t('title.title')">
        <el-input
          v-model="form.templateTitle"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.native.enter="queryClick"
        />
      </el-form-item>
      <!--内容-->
      <el-form-item v-if="showRow" :label="$t('title.content')">
        <el-input
          v-model="form.templateContent"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          @keyup.native.enter="queryClick"
        />
      </el-form-item>
      <el-form-item>
        <el-button :loading="queryLoading" type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
        <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        <el-button type="text" @click="showRowClick()">{{ showRow ? $t("page.hide") : $t("page.expand") }}</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <!--添加模板-->
        <el-button v-permission="'EmailTemplate:add'" size="small" type="primary" @click="handleAdd">{{ $t('title.Addtemplate') }}</el-button>
        <!--批量使用-->
        <el-button :disabled="!sidvoList.length" size="small" type="primary" @click="BatchDistributionWarehouse">{{ $t('tips.Batchuse') }}</el-button>
      </template>
    </vxe-toolbar>
    <!-- table表格 -->
    <vxe-table
      ref="table"
      :checkbox-config="{checkMethod: ({row}) => +row.templateStatus === 0}"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      class="mb-3"
      resizable
      @checkbox-all="handleSelectionChange"
      @checkbox-change="handleSelectionChange"
    >
      <vxe-column type="checkbox" width="40" />
      <!--标题-->
      <vxe-column :title="$t('title.title')" field="templateTitle" />
      <!--内容-->
      <vxe-column :title="$t('title.content')" field="templateContent" sortable>
        <template v-slot="scope">
          <el-popover :disabled="scope.row.templateContent.length <= 100" trigger="hover" width="300">
            <div>{{ scope.row.templateContent }}</div>
            <span v-if="scope.row.templateContent.length <= 100" slot="reference">{{ scope.row.templateContent }}</span>
            <span v-if="scope.row.templateContent.length > 100" slot="reference">{{ scope.row.templateContent.substr(0, 100) + "..." }}</span>
          </el-popover>
        </template>
      </vxe-column>
      <!--分类-->
      <vxe-column :title="$t('title.classify')" field="typeName" width="120" />
      <!--模板类型-->
      <vxe-column :title="$t('page.templateType')" width="100">
        <template v-slot="{row}">
          {{ templateTypeMap[row.templateType] }}
        </template>
      </vxe-column>
      <!--状态-->
      <vxe-column :title="$t('page.status')" field="templateStatusName" width="80">
        <template v-slot="scope">
          <span v-if="+scope.row.templateStatus === 0">{{ $t('page.enabled') }}</span>
          <span v-if="+scope.row.templateStatus === 1">{{ $t('page.disable') }}</span>
        </template>
      </vxe-column>
      <!--平台-->
      <vxe-column :title="$t('title.platform')" field="platform" width="110" />
      <!--创建人-->
      <vxe-column :title="$t('page.createByName')" field="createName" width="100" />
      <vxe-column :title="$t('page.operate')" field="operate" width="180">
        <template v-slot="scope">
          <!-- 启用 -->
          <el-button v-if="scope.row.templateStatus === 1" v-permission="'EmailTemplate:enabled'" type="text" @click="handleEnabled(scope.row,0)">{{ $t("page.enabled") }}</el-button>
          <!--禁用-->
          <el-button v-if="scope.row.templateStatus === 0" v-permission="'EmailTemplate:disable'" type="text" @click="handleEnabled(scope.row,1)">{{ $t("page.disable") }}</el-button>
          <!--修改-->
          <el-button v-permission="'EmailTemplate:modify'" type="text" @click="handleEdit(scope.row)">{{ $t("page.modify") }}</el-button>
          <!--删除-->
          <el-button v-if="scope.row.templateStatus === 1" v-permission="'EmailTemplate:delete'" type="text" @click="handleDelete(scope.row)">{{ $t("page.delete") }}</el-button>
          <!--使用模板-->
          <el-button v-if="scope.row.templateStatus === 0 && scope.row.isUsed === false" v-permission="'EmailTemplate:usetemplate'" type="text" @click="handleUse(scope.row,$store.getters.userId,$store.getters.name)">{{ $t("title.Usetemplates") }}</el-button>
          <!--弃用模板-->
          <el-button v-if="scope.row.templateStatus === 0 && scope.row.isUsed === true" v-permission="'EmailTemplate:deprecated'" type="text" @click="handleDeprecated(scope.row)">{{ $t("title.Deprecatedtemplates") }}</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <el-pagination
      ref="pager"
      :current-page="pager.current"
      :page-size="pager.size"
      :page-sizes="[10, 50, 100, 200, 300]"
      :total="pager.total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog
      :close-on-click-modal="false"
      :title="dialogType === 1 ? $t('title.Addtemplates') : $t('title.Modifythetemplate') "
      :visible.sync="dialogVisible"
      width="60%"
    >
      <el-form ref="addForm" :model="addForm" label-width="auto">
        <el-form-item :label="$t('title.Templateclassify')">
          <el-select v-model="addForm.typeName" :placeholder="$t('page.selectPlaceholder')" filterable>
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.typeName"
              :value="item.typeName"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('page.templateType')">
          <el-select v-model="addForm.templateType" :placeholder="$t('page.selectPlaceholder')">
            <el-option
              v-for="item in templateTypeList"
              :key="item.value"
              :label="item.label"
              :value="+item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('title.Templatetitle')">
          <el-input v-model="addForm.templateTitle" />
        </el-form-item>
        <el-form-item :label="$t('title.Templatecontent')" prop="content">
          <el-input id="textarea" v-model="addForm.templateContent" type="textarea" @blur="changeBlur" />
          <div class="emoji">
            <i class="el-icon-s-custom" @click="faceContent" />
            <el-dropdown @command="nikeName">
              <span class="el-dropdown-link ml-3">
                {{ $t('title.Insert') }}<i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="客户昵称">{{ $t('title.Customerid') }}</el-dropdown-item>
                <el-dropdown-item command="账号名称">{{ $t('title.Account') }}</el-dropdown-item>
                <el-dropdown-item command="客服名称">{{ $t('title.Agentname') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 表情区域 -->
          <div v-if="faceShow" class="browBox">
            <ul>
              <li v-for="(item, index) in faceList" :key="index" @click="getBrow(index)">
                {{ item }}
              </li>
            </ul>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t("title.cancel") }}</el-button>
        <el-button :loading="saveloading" type="primary" @click="saveSure(2,false)">{{ $t("title.SaveAndSend") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryTerraceList } from '@/api/omsorder'
import {
  checkSensitiveWord,
  deleteTemplatePersonById,
  deleteTemplateStatusById,
  getAllTemplateType,
  getTemplate,
  insertTemplate,
  insertTemplatePerson,
  updateTemplate,
  updateTemplateStatusById
} from '@/api/service-management'
import store from '@/store'
import mixin from '@/mixin/oms-mixin'
import { debounceGetTableMaxHeight } from '@/utils'
import { templateTypeList, templateTypeMap } from './const'

const appData = require('@/views/service-manage/emoji.json')
export default {
  mixins: [mixin],
  data() {
    return {
      queryLoading: false,
      maxHeight: 0,
      row: {
        edit: false
      },
      saveloading: false,
      showRow: false,
      addForm: {
        templateType: '',
        templateContent: ''
      },
      dialogType: 1,
      status: 1,
      dialogVisible: false,
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      form: {
        templateType: '',
        platform: '',
        type: '',
        typeName: '',
        templateTitle: '',
        templateStatus: '',
        templateContent: ''
      },
      platformList: [], // 平台条件查询
      typeList: [], // 分类条件查询
      tableData: [],
      faceList: [], // 表情存储
      faceShow: false, // 控制表情区域显示隐藏
      sidvoList: []
    }
  },
  computed: {
    templateTypeMap() {
      return templateTypeMap
    },
    templateTypeList() {
      return templateTypeList
    },
    queryParameter() {
      return {
        ...this.form,
        ...this.pager
      }
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        this.addForm = this.$options.data().addForm
      }
    }
  },
  created() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this._queryTerraceList()
    this._getAllTemplateType()
    this._getTemplate()
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    handleSelectionChange() {
      const selected = this.$refs.table.getCheckboxRecords()
      this.sidvoList = selected.map(e => e.id)
    },
    BatchDistributionWarehouse() {
      this.$confirm(this.$t('tips.Confirmbatchuse'),
        this.$t('page.Prompt'),
        {
          confirmButtonText: this.$t('title.confirm'),
          cancelButtonText: this.$t('title.cancel'),
          type: 'warning',
          beforeClose: async(action, instance, done) => {
            if (action === 'confirm') {
              instance.cancelButtonLoading = instance.confirmButtonLoading = true
              this._insertTemplatePerson({
                templateIds: this.sidvoList,
                userId: this.$store.getters.userId,
                userName: this.$store.getters.name
              }).finally(() => {
                instance.cancelButtonLoading = instance.confirmButtonLoading = false
              })
              done()
            } else {
              done()
            }
          }
        }
      ).catch(() => {

      })
    },
    async _insertTemplatePerson(arr) {
      const { code, msg } = await insertTemplatePerson(arr)
      code === 0 ? this.$message.success(msg) : this.$message.error(msg)
      await this._getTemplate()
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getTemplate()
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this._getTemplate(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 启用
    async handleEnabled(row, status) {
      const { id } = row
      const { code, msg } = await updateTemplateStatusById({ templateId: id, templateStatus: status })
      code === 0 ? this.$message.success(msg) : this.$message.error(msg)
      this._getTemplate()
    },
    // 删除模板
    async handleDelete(row) {
      const { code, msg } = await deleteTemplateStatusById({ templateId: row.id })
      if (code === 0) {
        this.$message.success(msg)
      }
      this._getTemplate()
    },
    // 使用个人模板
    async handleUse(row, userId, userName) {
      const { code, msg } = await insertTemplatePerson({ templateId: row.id, userId: userId, userName: userName })
      if (code === 0) {
        this.$message.success(msg)
      }
      this._getTemplate()
    },
    // 弃用个人模板
    async handleDeprecated(row) {
      const { code, msg } = await deleteTemplatePersonById({ templateId: row.id })
      if (code === 0) {
        this.$message.success(msg)
      }
      this._getTemplate()
    },
    // 添加模式
    handleAdd() {
      this.dialogType = 1
      this.dialogVisible = true
    },
    handleEdit(row,) {
      // 解构回显数据
      const { typeName, templateTitle, templateContent, templateStatus, id, templateType } = row
      this.addForm = { typeName, templateTitle, templateContent, templateStatus, id, templateType }
      this.dialogType = 2
      this.dialogVisible = true
    },
    //  新增/修改时 保存发布 或者提交审核
    async saveSure(saveType, Boolean) {
      if (this.addForm.templateType === '' || this.addForm.templateType === undefined) {
        return this.$message.error('请选择模板类型')
      }
      const val = this.typeList.find(e => e.typeName === this.addForm.typeName ? e.platform : '')
      const { code, msg } = await checkSensitiveWord({
        sensitiveWord: this.addForm.templateContent,
        platform: val.platform
      })
      if (code === 0) {
        try {
          this.saveloading = true
          saveType === 1 ? (this.status = 2) : (this.status = 0)
          const { typeName, templateTitle, templateContent, templateStatus, id, templateType } = this.addForm
          const obj1 = Object.assign({}, { typeName, templateTitle, templateContent, templateStatus, templateType }, { isApprove: Boolean }, { templateStatus: this.status }, { createName: store.getters.name })
          const obj2 = Object.assign({}, { typeName, templateTitle, templateContent, templateStatus, id, templateType }, { isApprove: Boolean }, { templateStatus: this.status }, { createName: store.getters.name })
          // 添加模板的时候不要传id
          if (this.dialogType === 1) {
            const { code } = await insertTemplate(obj1)
            if (code === 0) {
              this.$message.success('添加成功')
              this.dialogVisible = false
              this._getTemplate()
              this.saveloading = false
            }
          } else {
            const { code } = await updateTemplate(obj2)
            if (code === 0) {
              this.$message.success('修改成功')
              this.dialogVisible = false
              this._getTemplate()
              this.saveloading = false
            }
          }
        } finally {
          this.saveloading = false
        }
      } else {
        this.$message.success(msg)
      }
    },
    // 获取所有分类
    async _getAllTemplateType() {
      const { datas } = await getAllTemplateType()
      this.typeList = datas
      await this._getTemplate()
    },
    // 默认列表查询
    async _getTemplate() {
      try {
        this.queryLoading = true
        const { datas } = await getTemplate(this.queryParameter)
        this.tableData = datas.records
        this.pager = datas.pager
        this.queryLoading = false
      } catch (err) {
        console.log(err)
      } finally {
        this.queryLoading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._getTemplate()
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getTemplate()
    },
    // 获取平台消息
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platformList = datas
      this.platformList = this.platformList.filter((item) => {
        return item.platformCode === 'amazon' ||
        item.platformCode === 'ebay' ||
        item.platformCode === 'walmart' ||
        item.platformCode === 'Shopify' ||
        item.platformCode === 'standwebsite' ||
        // sb正式测试不一样
        item.platformCode === 'Tik Tok' ||
        item.platformCode === 'TikTok'
      })
    },
    async nikeName(val) {
      this.selectEmoji(val)
    },
    changeBlur() {
      this.faceShow = false
    },
    selectEmoji(emoji) {
      var elInput = document.getElementById('textarea') // 根据id选择器选中对象
      var startPos = elInput.selectionStart// input a第0个字符到选中的字符
      var endPos = elInput.selectionEnd// 选中的字符到最后的字符

      if (startPos === undefined || endPos === undefined) return
      var txt = elInput.value
      // 将表情添加到选中的光标位置
      var result = txt.substring(0, startPos) + emoji + txt.substring(endPos)
      // elInput.value = result// 赋值给input的value
      this.addForm.templateContent = result
    },
    getBrow(index) {
      for (const i in this.faceList) {
        if (index + '' === i) {
          this.getBrowString = this.faceList[index]
          this.addForm.templateContent += this.getBrowString
        }
      }
    },
    faceContent() {
      this.faceShow = !this.faceShow
      if (this.faceShow === true) {
        for (const i in appData) {
          this.faceList.push(appData[i].char)
        }
      } else {
        this.faceList = []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.emoji{
    width: 220px;
    display: flex;
    align-items: center;
}
.browBox {
  width: 100%;
  height: 130px;
  background: #e6e6e6;
  position: absolute;
  top: 55px;
  overflow: scroll;
  z-index: 999;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    li {
      width: 7%;
      font-size: 20px;
      list-style: none;
      text-align: center;
    }
  }
}
</style>
